import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet blanc tirant a beix grisenc posseeix un capell de 8 a 15 cm de diàmetre, primer hemisfèric i posteriorment aplanat. La cutícula pot estar clivellada i el marge és irregular i esfilegassat. Té nombroses làmines blanques i lliures que groguegen amb l’edat. El peu és espès, llarg i corbat i conté un anell fugaç, estriat. Té presència de volva blanquinosa que es sol rompre i separar-se del peu en trossos d’aparença cotonosa. Les espores són blanques en massa, el·líptiques, d’11-13 x 6-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      